import { Button, Col } from 'antd'
import MobileBottomModal from 'components/modal/MobileBottomModal'
import { useAppState } from 'state'

interface TypeOfNetworkModalProps {
  isModalOpen: boolean
  handleModalOpen: () => void
}

const TypeOfNetworkModal: React.FC<TypeOfNetworkModalProps> = ({ isModalOpen, handleModalOpen }) => {
  const { IsMobile } = useAppState()
  const isMobile = IsMobile()

  const buttonStyle = {
    width: isMobile ? '100%' : '49%',
  }

  const infoData = [
    {
      header: 'SWTCH Communication Hardware',
      body: 'You have successfully set up an LTE modem, PoE switch, and wireless access points.',
    },
    {
      header: 'Building Network',
      body: `The chargers are connected to the building's internet via WiFi or Ethernet cable.`,
    },
    {
      header: 'Cellular',
      body: 'The charger has a SIM card inserted and is using it to communicate.',
    },
    {
      header: 'Non-networked Unit ',
      body: 'The charger is a basic plug-and-play unit without any communication features.',
    },
  ]

  return (
    <MobileBottomModal
      isModalOpen={isModalOpen}
      centered={!isMobile}
      saveTitle="OK"
      onClose={handleModalOpen}
      onFinish={handleModalOpen}
      title={'Type of Network'}
      footer={
        <Button key="submit" onClick={handleModalOpen} style={buttonStyle}>
          {'OK'}
        </Button>
      }
    >
      <Col>
        {infoData.map((info) => (
          <div style={{ marginBottom: 32 }} key={info.header}>
            <p className="heading-06-regular">{info.header}</p>
            <p className="heading-06-light">{info.body}</p>
          </div>
        ))}
      </Col>
    </MobileBottomModal>
  )
}

export default TypeOfNetworkModal
