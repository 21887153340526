import { Badge } from 'antd'

interface CoreBadgeProps {
  text: string | JSX.Element
  backgroundColor: string
  color: string
}

const CoreBadge: React.FC<CoreBadgeProps> = ({ text, backgroundColor, color }) => {
  return (
    <Badge
      key={`badge-${color}-${Math.random()}`}
      count={text}
      style={{
        backgroundColor,
        color,
        boxShadow: `${backgroundColor} 0px 0px 0px 0.5px`,
      }}
    />
  )
}

export default CoreBadge
