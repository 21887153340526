import { AutoComplete, AutoCompleteProps, Input } from 'antd'
import { useEffect, useState } from 'react'

import configProvider from 'config'

interface GoogleAutoCompleteProps extends AutoCompleteProps {}

const GoogleAutoComplete: React.FC<GoogleAutoCompleteProps> = ({ ...props }) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null)

  const countryRestriction = ['ca', 'us']

  const loadGoogleMapsScript = (apiKey: string, callback: () => void) => {
    if (!document.querySelector(`#google-maps-script`)) {
      const script = document.createElement('script')
      script.id = 'google-maps-script'
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
      script.async = true
      script.onload = callback // Callback after script is loaded
      document.head.appendChild(script)
    } else {
      callback() // If script is already loaded, execute callback
    }
  }

  // Load the Google Places Autocomplete Service once the component is mounted
  useEffect(() => {
    loadGoogleMapsScript(configProvider.config.googleMapsApiKey, () => {
      const checkGoogle = setInterval(() => {
        if (window.google) {
          clearInterval(checkGoogle)
          const service = new window.google.maps.places.AutocompleteService()
          setAutocompleteService(service)
        }
      }, 100)
    })
  }, [])

  // Handle search input and fetch suggestions from Google Places API
  const handleSearch = (value: string) => {
    if (!value || !autocompleteService) {
      setOptions([])
      return
    }

    autocompleteService.getPlacePredictions(
      { input: value, componentRestrictions: { country: countryRestriction } },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          const newOptions = predictions.map((prediction) => ({
            value: prediction.description,
            label: prediction.description,
          }))
          setOptions(newOptions)
        }
      },
    )
  }

  const handleBlur = () => {
    const isValueSelected = options.some((option) => option.value === props?.value)
    if (!isValueSelected) {
      handleClear()
    }
  }

  const handleClear = () => {
    if (props?.onSelect) props?.onSelect('', {} as any)
  }

  return (
    <AutoComplete {...props} options={options} onSearch={handleSearch} onBlur={handleBlur} onClear={handleClear}>
      <Input />
    </AutoComplete>
  )
}

export default GoogleAutoComplete
