import { Button, Col } from 'antd'
import MobileBottomModal from 'components/modal/MobileBottomModal'
import { useAppState } from 'state'

interface PhotoGuideLineModalProps {
  isModalOpen: boolean
  handleModalOpen: () => void
}

const PhotoGuideLineModal: React.FC<PhotoGuideLineModalProps> = ({ isModalOpen, handleModalOpen }) => {
  const { IsMobile } = useAppState()
  const isMobile = IsMobile()

  const buttonStyle = {
    width: isMobile ? '100%' : '49%',
  }

  const infoData = [
    {
      image: 'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg',
      alt: 'Photo guide line image 1',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      image: 'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg',
      alt: 'Photo guide line image 2',
      body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..`,
    },
  ]

  return (
    <MobileBottomModal
      isModalOpen={isModalOpen}
      centered={!isMobile}
      saveTitle="OK"
      onClose={handleModalOpen}
      onFinish={handleModalOpen}
      title={'Photo Guidelines'}
      footer={
        <Button key="submit" onClick={handleModalOpen} style={buttonStyle}>
          {'OK'}
        </Button>
      }
    >
      <Col>
        {infoData.map((info) => (
          <div style={{ marginBottom: 32 }} key={info.image}>
            <img alt={info.alt} style={{ width: '100%' }} src={info.image} />
            <p className="heading-06-regular">{info.body}</p>
          </div>
        ))}
      </Col>
    </MobileBottomModal>
  )
}

export default PhotoGuideLineModal
