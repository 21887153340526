import { Form } from 'antd'
import AddChargerForm from 'components/form/projects/AddChargerForm'
import MobileBottomModal from 'components/modal/MobileBottomModal'
import { SelfServePanelChargerSchema, SelfServePanelSchema } from 'models/self-serve-commissioning'
import { useAppState } from 'state'

interface AddChargerModalProps {
  isModalOpen: boolean
  handleModalOpen: () => void
  initialValues?: SelfServePanelChargerSchema
  panels?: SelfServePanelSchema[]
  updateInstallation: (
    request: SelfServePanelChargerSchema,
    existingPanel?: SelfServePanelChargerSchema,
    callBack?: () => void,
  ) => void
}

const AddChargerModal: React.FC<AddChargerModalProps> = ({
  isModalOpen,
  handleModalOpen,
  panels,
  initialValues,
  updateInstallation,
}) => {
  const { IsMobile } = useAppState()
  const isMobile = IsMobile()
  const [form] = Form.useForm()

  return (
    <MobileBottomModal
      isModalOpen={isModalOpen}
      centered={!isMobile}
      saveTitle="Save"
      onClose={handleModalOpen}
      onFinish={form.submit}
      title={'Add a Charger'}
      onCancel={handleModalOpen}
      destroyOnClose
    >
      <AddChargerForm
        form={form}
        panels={panels}
        handleModalOpen={handleModalOpen}
        initialValues={initialValues}
        updateInstallation={updateInstallation}
      />
    </MobileBottomModal>
  )
}

export default AddChargerModal
