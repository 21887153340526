import { Col, Row } from 'antd'
import { theme } from 'theme'

const InstallationForm: React.FC = () => {
  return (
    <Col>
      <p className="heading-03-bold">{'Installation'}</p>
      <p className="heading-06-regular">{'Ensure chargers are powered on!'}</p>
      <img
        style={{ width: '100%' }}
        alt={'Installation instruction'}
        src={'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg'}
      />
      {/* Replace later with actual image*/}
      <p className="heading-05-regular" style={{ margin: '16px 0px' }}>
        {'Eligibility Criteria'}
      </p>
      {['Liteon w/microhard', 'No Load Management', 'Signature', 'Pre-activated SIM card'].map((option) => (
        <Row align="middle">
          <div style={{ margin: '0px 8px', width: 5, height: 5, borderRadius: 5, backgroundColor: 'black' }} />
          <p className="heading-06-regular" style={{ marginBottom: 0 }}>
            {option}
          </p>
        </Row>
      ))}
      <p className="heading-05-regular" style={{ margin: '16px 0px' }}>
        {'What you will need'}
      </p>
      {['Charger S/N', 'Charger ID', 'Photos of the installed charger', 'Panel Information'].map((option) => (
        <Row align="middle">
          <div style={{ margin: '0px 8px', width: 5, height: 5, borderRadius: 5, backgroundColor: 'black' }} />
          <p className="heading-06-regular" style={{ marginBottom: 0 }}>
            {option}
          </p>
        </Row>
      ))}
      <p className="paragraph-01-regular" style={{ marginTop: 32, color: theme.colors.darkGray }}>
        {
          'For the SWTCH team to set up the site correctly, we require accurate information and has passed ESA inspection. This information is crucial to prevent any operational issues and/or damage to the building.'
        }
      </p>
    </Col>
  )
}

export default InstallationForm
