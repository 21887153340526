import { theme } from 'theme'

export const profileSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
    <path
      stroke={theme.colors.black}
      strokeLinecap="round"
      strokeWidth="2"
      d="M1.667 12.333c.93-1.572 3.04-2.648 5.333-2.648 2.294 0 4.402 1.076 5.333 2.648M9.528 4.226c0 1.414-1.132 2.56-2.528 2.56-1.397 0-2.528-1.146-2.528-2.56 0-1.413 1.131-2.56 2.528-2.56 1.396 0 2.528 1.147 2.528 2.56z"
    ></path>
  </svg>
)
