import { SelfServeSummaryInfoDataProps } from 'components/card'
import { SummaryFormProps } from 'components/form/projects/SummaryForm'
import { flatMap } from 'lodash'
import { SelfServePanelChargerSchemaResponse, SelfServePanelSchemaResponse } from 'models/self-serve-commissioning'

const useSummaryForm = ({ currentValues, goToStep }: SummaryFormProps) => {
  const typeOfNetworks = {
    swtch: 'SWTCH Communication Hardware',
    building_network: 'Building Network',
    cellular: 'Cellular',
    non_networked: 'Non-networked',
  }

  const nonEditable = currentValues?.status === 'assistance_required' || currentValues?.status === 'complete'
  const stepTypes: {
    [key in 'network' | 'panel' | 'charger']: number
  } = {
    network: 1,
    panel: 2,
    charger: 3,
  }

  const networkData: SelfServeSummaryInfoDataProps[] = [
    {
      title: 'Installation Address',
      body: currentValues?.site?.address,
    },
    {
      title: 'Type of Network',
      body: typeOfNetworks[currentValues?.networks?.[0]?.type as keyof typeof typeOfNetworks],
    },
    {
      title: 'Network PIN',
      body: currentValues?.networks?.[0]?.pin,
    },
    {
      title: 'Photos of Router Placement',
      photos: currentValues?.networks?.[0]?.photos?.filter(({ type }) => type === 'router'),
      bodyType: 'image',
    },
    {
      title: 'Photos of POE Switch Location',
      photos: currentValues?.networks?.[0]?.photos?.filter(({ type }) => type === 'poe'),
      bodyType: 'image',
    },
  ]

  const panelData = (panel: SelfServePanelSchemaResponse, index: number): SelfServeSummaryInfoDataProps[] => [
    {
      header: `Panel ${index + 1}`,
      title: 'Panel Label',
      body: panel?.label,
    },
    {
      title: 'Panel Type',
      body: panel?.type,
    },
    {
      title: 'Panel Size (A)',
      body: `${panel?.ratedCapacity}`,
    },
    {
      title: 'Panel Location',
      body: panel?.loc,
    },
    {
      title: 'Photos of Panel',
      photos: panel?.photos,
      bodyType: 'image',
    },
    ...flatMap(
      (panel?.breakers || [])?.map((breaker, index) => [
        {
          header: `Breaker ${index + 1}`,
          title: 'Breaker Label',
          body: breaker?.breakerNumber,
        },
        {
          title: 'Breaker Size (A)',
          body: `${breaker?.ratedCapacity}`,
        },
      ]),
    ),
  ]

  const chargerData = (
    charger: SelfServePanelChargerSchemaResponse,
    index: number,
  ): SelfServeSummaryInfoDataProps[] => [
    {
      header: `Charger ${index + 1}`,
      title: 'Charger ID',
      body: charger?.connectors?.[0]?.listingTitle,
    },
    {
      title: 'Charger S/N',
      body: charger?.serialNumber,
    },
    {
      title: 'Charger S/N',
      body: charger?.serialNumber,
    },
    {
      title: 'Breaker',
      body: charger?.breakerNumber,
    },
    {
      title: 'Photos of Charger',
      photos: charger?.photos,
      bodyType: 'image',
    },
  ]

  const onEdit = (type: 'network' | 'panel' | 'charger') => () => {
    goToStep(stepTypes[type])
  }
  return { networkData, panelData, chargerData, onEdit, nonEditable }
}

export default useSummaryForm
