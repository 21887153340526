import { useAppState } from 'state'
import CoreModal, { CoreModalProps } from './CoreModal'
import { theme } from 'theme'
interface MobileBottomModalProps {}

const MobileBottomModal: React.FC<CoreModalProps & MobileBottomModalProps> = ({ title, ...props }) => {
  const { IsMobile } = useAppState()

  const isMobile = IsMobile()
  return (
    <CoreModal
      {...props}
      style={{ top: 'auto' }}
      className={isMobile ? 'mobile-bottom' : ''}
      closable={!isMobile}
      width={!isMobile ? 600 : 800}
      transitionName={isMobile ? 'modal-slide' : ''}
      title={
        isMobile ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div
              style={{
                marginBottom: 16,
                width: '60px',
                height: '4px',
                borderRadius: '8px',
                backgroundColor: theme.colors.lightGray,
              }}
            />
            <span>{title}</span>
          </div>
        ) : (
          title
        )
      }
    />
  )
}

export default MobileBottomModal
