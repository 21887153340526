import { ChargersListFormProps } from 'components/form/projects/ChargersListForm'
import { SelfServePanelChargerSchema } from 'models/self-serve-commissioning'
import { useState } from 'react'
import { theme } from 'theme'

const useChargerListForm = ({
  initialValues,
  updateInstallation,
}: Omit<ChargersListFormProps, 'panels' | 'connectivity' | 'getChargerConnectivity'>) => {
  const [openAddCharger, setOpenAddCharger] = useState(false)
  const [selectedCharger, setSelectedCharger] = useState<SelfServePanelChargerSchema>()
  const connectivityColors = {
    strong: theme.colors.successPrimary,
    weak: theme.colors.dangerPrimary,
    acceptable: theme.colors.warningPrimary,
    '-': theme.colors.successPrimary,
  }

  const handleAddCharger = (charger?: SelfServePanelChargerSchema) => () => {
    setSelectedCharger(charger)
    setOpenAddCharger(!openAddCharger)
  }

  const updateCharger = (
    updatedChargerRequest: SelfServePanelChargerSchema,
    existingCharger?: SelfServePanelChargerSchema,
    callBack?: () => void,
  ) => {
    let updatedCharger = [...(initialValues || [])]
    const updatedChargerIndex = updatedCharger?.findIndex(
      (currentPanel) => currentPanel?.serialNumber === existingCharger?.serialNumber,
    )
    if (updatedChargerIndex > -1) {
      updatedCharger[updatedChargerIndex] = {
        ...updatedCharger[updatedChargerIndex],
        ...updatedChargerRequest,
      }
    } else {
      updatedCharger.push(updatedChargerRequest)
    }
    updateInstallation(updatedCharger, callBack)
  }

  const deleteCharger = (panel: SelfServePanelChargerSchema) => () => {
    let updatedCharger = [...(initialValues || [])]
    const updatedChargerIndex = updatedCharger?.findIndex(
      (currentPanel) => currentPanel?.serialNumber === panel?.serialNumber,
    )
    updatedCharger.splice(updatedChargerIndex, 1)
    updateInstallation(updatedCharger)
  }

  const panelModifyOptions = (charger: SelfServePanelChargerSchema) => [
    {
      onClick: handleAddCharger(charger),
      title: 'Edit',
    },
    {
      onClick: deleteCharger(charger),
      title: 'Delete',
    },
  ]

  return { openAddCharger, selectedCharger, connectivityColors, handleAddCharger, updateCharger, panelModifyOptions }
}

export default useChargerListForm
