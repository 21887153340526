import CoreButton, { CoreButtonProps } from './CoreButton'

interface IconButtonProps extends CoreButtonProps {
  icon: React.ReactNode
}
const IconButton: React.FC<IconButtonProps> = ({ icon, ...props }) => {
  return <CoreButton {...props} type="text" icon={icon} />
}

export default IconButton
