// Import necessary components and libraries
import { Col, Layout, Menu, MenuProps, Row } from 'antd'
import { useHistory, useLocation } from 'react-router'

import { FileTextFilled, ProfileFilled } from '@ant-design/icons'
import { profileSVG } from 'assets/svg/profile'
import CoreButton from 'components/button/CoreButton'
import { isMobileFullView, mobileFullViewPath } from 'helpers/routes'
import { logoutSVG } from '../../../assets/svg/logout'
import { panelOverviewSVG } from '../../../assets/svg/panel'
import { pieChartSVG } from '../../../assets/svg/pieChart'
import { AppHeaderContainer, HeadingWrapper } from '../../../atom/app-header'
import { useAppState } from '../../../state'
import { styled, theme } from '../../../theme'
import { Links } from '../../routes/paths'

// Destructure components from antd library
const { Header } = Layout

// Styled component for menu spacing
const MenuSpacing = styled.div`
  .ant-layout-header {
    padding: 0 21px 0 15px;
    height: 55px;
  }
`

// Styled component for logout text
const LogoutTextWrapper = styled.span`
  font-family: ${(props) => props.theme.fontFamily.regular};
  color: #242e2c;
`

// Styled component for logout wrapper
const LogoutWrapper = styled.div`
  display: flex;
  justify-content: end;
  font-family: ${(props) => props.theme.fontFamily.regular};
  height: 100%;
  .ant-menu-item {
    display: flex;
    align-items: center;
    background-color: white;
  }
`

// Props interface for the component
interface props {
  collapsed: boolean
  handleToggleMenu(): void
  setShowProfile: (value: boolean) => void
}

// AppHeader component
export const AppHeader: React.FC<props> = ({ collapsed, handleToggleMenu, setShowProfile }) => {
  const { currentUser, logout, IsMobile, IsTablet } = useAppState()

  // Check if the device is mobile or tablet
  const isMobile = IsMobile()
  const isTablet = IsTablet()
  const history = useHistory()
  const location = useLocation()

  const mobileFullView = isMobileFullView(location.pathname, isMobile)
  const splitPathName = location.pathname.split('/')

  const showProfileButton = mobileFullViewPath.includes(splitPathName[1])

  // Handle the logout action
  const handleLogout: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      logout()
      history.push(Links.auth0login())
    }
  }

  const openProfile = () => setShowProfile(true)

  // If there's no current user, return an empty div
  if (!currentUser) {
    return <div />
  }

  // Function to render the title and icon in the header
  const renderTitle = () => {
    let title = ''
    let icon = pieChartSVG

    switch (splitPathName[1]) {
      case 'panel-overview':
        icon = panelOverviewSVG
        title = 'Panel Overview'
        break
      case 'template':
        title = 'Template'
        icon = <FileTextFilled style={{ color: theme.colors.primary }} />
        break
      case 'charger-models':
        title = 'Charger Models'
        icon = <ProfileFilled style={{ color: theme.colors.primary }} />
        break
      case 'information':
        title = 'Info'
        icon = <ProfileFilled style={{ color: theme.colors.primary }} />
        break
      case 'projects':
        title = 'Projects'
        icon = <ProfileFilled style={{ color: theme.colors.primary }} />
        break
      default:
        icon = pieChartSVG
        title = 'Deployment Dashboard'
        break
    }

    return (
      <HeadingWrapper>
        {!mobileFullView && <span style={{ position: 'absolute', left: '5px', top: '16px' }}>{icon}</span>}
        <div className={mobileFullView ? 'heading-03-bold' : 'heading-04-regular'}>{title}</div>
      </HeadingWrapper>
    )
  }

  // Return the JSX for the AppHeader component
  return (
    <AppHeaderContainer mobileFullView={mobileFullView}>
      <MenuSpacing style={{ zIndex: 1 }}>
        <Header className={mobileFullView ? 'mobile-header' : ''}>
          <Row justify="space-between">
            <Col xs={10} sm={23} md={17} lg={18} xl={20} xxl={20}>
              {mobileFullView && isMobile && showProfileButton && (
                <CoreButton onClick={openProfile} icon={profileSVG} />
              )}
              <h1
                className="heading-01-regular dashboard-title"
                style={mobileFullView ? {} : { marginTop: '15px', marginLeft: '33px' }}
              >
                {renderTitle()}
              </h1>
            </Col>
            <Col xs={12} sm={1} md={7} lg={6} xl={4} xxl={4}>
              <LogoutWrapper>
                {showProfileButton && !isMobile && (
                  <div style={{ alignItems: 'center' }}>{<CoreButton onClick={openProfile} icon={profileSVG} />}</div>
                )}
                <Menu
                  theme="light"
                  mode="horizontal"
                  style={{ float: isMobile || isTablet ? 'none' : 'right', backgroundColor: theme.colors.white }}
                >
                  <Menu.Item className="app-header" key={'logout'} icon={logoutSVG} onClick={handleLogout}>
                    <LogoutTextWrapper className="regular-cap">Log Out</LogoutTextWrapper>
                  </Menu.Item>
                </Menu>
              </LogoutWrapper>
            </Col>
          </Row>
        </Header>
      </MenuSpacing>
    </AppHeaderContainer>
  )
}
