import { Form, FormInstance, Input, Radio } from 'antd'
import { SelfServeRadioGroupWrapper } from 'atom/projects/radio-group'
import { styleDisplay } from 'atom/style/display'
import LabelWithSubtitle from 'components/label/LabelWithSubtitle'
import UploadImage from 'components/upload/UploadImage'
import { noSpecialCharacters, onlyNumbers, requiredRule } from 'helpers/rules'
import useAddPanelForm from 'hooks/projects/useAddPanelForm'
import { SelfServePanelSchema } from 'models/self-serve-commissioning'
import { UploadSelfServeImage } from 'services/data-provider/self-serve-commissioning'

export interface AddPanelFormProps {
  form: FormInstance<any>
  handleModalOpen: () => void
  initialValues?: SelfServePanelSchema
  updateInstallation: (
    request: SelfServePanelSchema,
    existingPanel?: SelfServePanelSchema,
    callBack?: () => void,
  ) => void
}

const AddPanelForm: React.FC<AddPanelFormProps> = ({ form, handleModalOpen, initialValues, updateInstallation }) => {
  const { currentInitialValues, typeOfPanel, handleValuesChange, handleImageUpload, onFinish } = useAddPanelForm({
    form,
    handleModalOpen,
    initialValues,
    updateInstallation,
  })

  return (
    <Form
      form={form}
      onValuesChange={handleValuesChange}
      layout="vertical"
      requiredMark={false}
      name="panel-form"
      onFinish={onFinish}
      initialValues={currentInitialValues}
    >
      <SelfServeRadioGroupWrapper>
        <Form.Item
          name="label"
          label={<LabelWithSubtitle title="Panel Label" subTitle="E.g. EV CHARGER 1 PANEL" />}
          rules={[requiredRule('Panel Label')]}
          style={styleDisplay(100)}
        >
          <Input placeholder="Panel Label" />
        </Form.Item>
        <Form.Item name="type" label={'Panel Type'} rules={[requiredRule('Panel Type')]} style={styleDisplay(100)}>
          <Radio.Group>
            {typeOfPanel.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="ratedCapacity"
          label={'Panel Size (A)'}
          rules={[requiredRule('Panel Size (A)'), noSpecialCharacters, onlyNumbers]}
          style={styleDisplay(100)}
        >
          <Input type="number" placeholder="Panel Size (A)" />
        </Form.Item>
        <Form.Item
          name="loc"
          label={<LabelWithSubtitle title="Panel Location" subTitle="E.g. Electrical room on P2" />}
          rules={[requiredRule('Panel Location')]}
          style={styleDisplay(100)}
        >
          <Input placeholder="Panel Location" />
        </Form.Item>
        <Form.Item name="phaseType" label={'Phase Type'} rules={[requiredRule('Phase Type')]} style={styleDisplay(100)}>
          <Radio.Group>
            {[
              { value: 'single', label: 'Single' },
              { value: 'three', label: 'Three' },
            ].map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="photos"
          label={'Photo of Panel'}
          rules={[requiredRule('Photo of Panel')]}
          style={styleDisplay(100)}
        >
          <UploadImage
            loadedImages={currentInitialValues?.photos || []}
            setLoadedImages={handleImageUpload}
            uploadFunction={UploadSelfServeImage}
            type="panel"
            singlePhoto
          />
        </Form.Item>
      </SelfServeRadioGroupWrapper>
    </Form>
  )
}

export default AddPanelForm
