export const filledCircleErrorSVG = (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="42" height="42" rx="21" fill="#EC4747" />
    <rect x="3" y="3" width="42" height="42" rx="21" fill="white" fill-opacity="0.85" />
    <rect x="3" y="3" width="42" height="42" rx="21" stroke="#FFF2F2" stroke-width="6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.9999 34.1004C29.3018 34.1004 33.5999 29.8023 33.5999 24.5004C33.5999 19.1985 29.3018 14.9004 23.9999 14.9004C18.698 14.9004 14.3999 19.1985 14.3999 24.5004C14.3999 29.8023 18.698 34.1004 23.9999 34.1004ZM24.9999 19.7004C24.9999 19.1481 24.5522 18.7004 23.9999 18.7004C23.4476 18.7004 22.9999 19.1481 22.9999 19.7004V24.5004C22.9999 25.0527 23.4476 25.5004 23.9999 25.5004C24.5522 25.5004 24.9999 25.0527 24.9999 24.5004V19.7004ZM24.9999 28.0582C24.9999 27.5059 24.5522 27.0582 23.9999 27.0582C23.4476 27.0582 22.9999 27.5059 22.9999 28.0582V28.1004C22.9999 28.6527 23.4476 29.1004 23.9999 29.1004C24.5522 29.1004 24.9999 28.6527 24.9999 28.1004V28.0582Z"
      fill="#EC4747"
    />
  </svg>
)
