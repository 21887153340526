import { Form } from 'antd'
import AddBreakerForm from 'components/form/projects/AddBreakerForm'
import MobileBottomModal from 'components/modal/MobileBottomModal'
import { SelfServeBreakerSchema } from 'models/self-serve-commissioning'
import { useAppState } from 'state'

interface AddBreakerModalProps {
  isModalOpen: boolean
  handleModalOpen: () => void
  initialValues?: SelfServeBreakerSchema
  updateInstallation: (request: SelfServeBreakerSchema, callBack?: () => void) => void
}

const AddBreakerModal: React.FC<AddBreakerModalProps> = ({
  isModalOpen,
  handleModalOpen,
  initialValues,
  updateInstallation,
}) => {
  const { IsMobile } = useAppState()
  const isMobile = IsMobile()
  const [form] = Form.useForm()
  return (
    <MobileBottomModal
      isModalOpen={isModalOpen}
      centered={!isMobile}
      saveTitle="Save"
      onClose={handleModalOpen}
      onFinish={form.submit}
      title={'Add a Breaker'}
      onCancel={handleModalOpen}
      destroyOnClose
    >
      <AddBreakerForm
        form={form}
        handleModalOpen={handleModalOpen}
        initialValues={initialValues}
        updateInstallation={updateInstallation}
      />
    </MobileBottomModal>
  )
}

export default AddBreakerModal
