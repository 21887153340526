import { isMobileFullView } from 'helpers/routes'
import { useLocation } from 'react-router'
import { useAppState } from 'state'

export interface CoreHeaderProps {
  title: string
  rightElement?: JSX.Element
}

const CoreHeader: React.FC<CoreHeaderProps> = ({ title, rightElement }) => {
  const { IsMobile } = useAppState()

  const isMobile = IsMobile()
  const location = useLocation()
  const mobileFullView = isMobileFullView(location.pathname, isMobile)

  return (
    <div className={`flex space-between ${mobileFullView ? 'column' : 'row'}`}>
      <p className="heading-06-regular" style={mobileFullView ? {} : { marginRight: 40 }}>
        {title}
      </p>
      {rightElement}
    </div>
  )
}

export default CoreHeader
