import { notification } from 'antd'
import { NotificationPlacement } from 'antd/lib/notification'

export const useNotifications = () => {
  const openErrorNotification = (
    message: React.ReactElement | string,
    style?: any,
    placement?: NotificationPlacement,
    duration?: number,
  ) => {
    notification.open({
      message,
      className: 'custom-message-banner error',
      placement: 'bottomRight',
      duration: duration || 6,
      onClick: () => {
        console.log('Notification Clicked!')
      },
    })
  }

  const openWarningNotification = (message: React.ReactElement) => {
    notification.open({
      message,
      className: 'custom-message-banner warning',
      placement: 'bottomRight',
      onClick: () => {
        console.log('Notification Clicked!')
      },
    })
  }

  const openSuccessNotification = (
    message: React.ReactElement | string,
    style?: any,
    placement?: NotificationPlacement,
    duration?: number,
  ) => {
    notification.open({
      message,
      style,
      className: 'custom-message-banner success',
      placement: placement || 'bottomRight',
      onClick: () => {
        console.log('Notification Clicked!')
      },
      duration: duration || 4,
    })
  }

  return { openErrorNotification, openWarningNotification, openSuccessNotification }
}
