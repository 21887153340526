interface LabelWithSubtitleProps {
  title: string
  subTitle: string
}

const LabelWithSubtitle: React.FC<LabelWithSubtitleProps> = ({ title, subTitle }) => {
  return (
    <div className="label-with-subtitle">
      <span>{title}</span>
      <span className="helper">{subTitle}</span>
    </div>
  )
}

export default LabelWithSubtitle
