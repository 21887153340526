import { Col, FormProps } from 'antd'
import { plusSVG } from 'assets/svg/plus'
import SecondaryButton from 'components/button/SecondaryButton'
import ErrorCard from 'components/card/ErrorCard'
import SelfServeInfoCard from 'components/card/SelfServeInfoCard'
import WarningCard from 'components/card/WarningCard'
import AddChargerModal from 'feature/modal/projects/AddChargerModal'
import useChargerListForm from 'hooks/projects/useChargerListForm'
import {
  SelfServeChargerConnectivitySchema,
  SelfServePanelChargerSchema,
  SelfServePanelSchema,
} from 'models/self-serve-commissioning'

export interface ChargersListFormProps extends FormProps {
  initialValues?: SelfServePanelChargerSchema[]
  panels?: SelfServePanelSchema[]
  connectivity: SelfServeChargerConnectivitySchema[]
  getChargerConnectivity: (serialNumbers: string[]) => Promise<void>
  updateInstallation: (request: SelfServePanelChargerSchema[], callBack?: () => void) => Promise<void>
}

const ChargersListForm: React.FC<ChargersListFormProps> = ({
  initialValues,
  panels,
  connectivity,
  getChargerConnectivity,
  updateInstallation,
}) => {
  const { openAddCharger, selectedCharger, connectivityColors, handleAddCharger, updateCharger, panelModifyOptions } =
    useChargerListForm({ initialValues, updateInstallation })

  return (
    <>
      <AddChargerModal
        panels={panels}
        updateInstallation={updateCharger}
        isModalOpen={openAddCharger}
        handleModalOpen={handleAddCharger(undefined)}
        initialValues={selectedCharger}
      />
      <Col>
        <p className="heading-03-bold">{'Chargers'}</p>
        <p className="heading-06-regular">
          {'Add all chargers and click ‘check connectivity’ to see connectivity status.'}
        </p>
        <SecondaryButton onClick={handleAddCharger(undefined)} size="large" title={`Add a charger`} icon={plusSVG} />
        {initialValues?.map((charger) => (
          <SelfServeInfoCard
            image={charger?.photos?.[0]}
            title={charger?.connectors?.[0]?.listingTitle}
            subtitles={[
              `Charger S/N: ${charger?.serialNumber}`,
              `${charger?.panelLabel} · ${charger?.breakerNumber} · ID ${charger?.connectors?.[0]?.connectorId}`,
              `Phase ${charger?.connectionPhase}`,
            ]}
            options={panelModifyOptions(charger)}
            body={{
              title: (
                <span>
                  {`Connectivity: `}
                  <span
                    style={{
                      color:
                        connectivityColors[
                          connectivity.find(({ serialNumber }) => serialNumber === charger.serialNumber)
                            ?.connectivityStatus || '-'
                        ],
                      textTransform: 'capitalize',
                    }}
                    className="paragraph-01-bold"
                  >
                    {connectivity.find(({ serialNumber }) => serialNumber === charger.serialNumber)
                      ?.connectivityStatus || '-'}
                  </span>
                </span>
              ),
              buttonText: 'Refresh',
              onClick: () => getChargerConnectivity([charger?.serialNumber || '']),
            }}
          />
        ))}
        <div style={{ marginTop: 20 }}>
          {connectivity.find(({ connectivityStatus }) => connectivityStatus === 'acceptable') ? (
            <WarningCard description="Message about they can still proceed now, but don’t be surprised if SWTCH calls them" />
          ) : connectivity.find(({ connectivityStatus }) => connectivityStatus === 'weak') ? (
            <ErrorCard description="guidance on how to improve connectivity" />
          ) : null}
        </div>
      </Col>
    </>
  )
}

export default ChargersListForm
