import styled from 'styled-components'

export const SelfServeRadioGroupWrapper = styled.div`
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .ant-radio-wrapper {
    padding: 12px 16px;
    width: 100%;
    border: ${(props) => `0.5px solid ${props.theme.colors.lightGray}`};
    border-left-width: 1px;
    border-right-width: 1px;

    &:first-of-type {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-top-width: 1px;
    }

    &:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom-width: 1px;
    }
  }

  .ant-radio-wrapper-checked {
    background-color: ${(props) => `${props.theme.colors.primary}0D`};
    border-color: ${(props) => props.theme.colors.primary};
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: ${(props) => props.theme.colors.primary};
  }

  .ant-radio-inner::after {
    background-color: ${(props) => props.theme.colors.white};
  }
`

export const SelfServeRadioInnerGroupWrapper = styled.div`
  .ant-radio-wrapper-checked {
    &:last-of-type {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-width: 0.5px;
    }
  }
`
export const SelfServeInsideRadioGroupWrapper = styled.div`
  padding: 16px 48px;
  background-color: ${(props) => props.theme.colors.grayBackground};
  border: ${(props) => `1px solid ${props.theme.colors.lightGray}`};
  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .ant-radio-wrapper-checked {
    &:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom-width: 0.5px;
    }
  }
`
