import { Row } from 'antd'
import { infoSVG } from 'assets/svg/info'
import IconButton from 'components/button/IconButton'

interface LabelWithInfoProps {
  onClick: () => void
  title: string
}

const LabelWithInfo: React.FC<LabelWithInfoProps> = ({ onClick, title }) => {
  return (
    <Row align="middle">
      <span>{title}</span>
      <IconButton onClick={onClick} icon={infoSVG} />
    </Row>
  )
}

export default LabelWithInfo
