import { ChevronRight } from 'assets/svg/chevron'
import { theme } from 'theme'

interface LabelWithButtonProps {
  onClick: () => void
  title: string
  buttonTitle: string
}

const LabelWithButton: React.FC<LabelWithButtonProps> = ({ onClick, title, buttonTitle }) => {
  return (
    <div className="label-with-button">
      <span>{title}</span>
      <span role="button" className="button-cap-03 pointer sub-button" onClick={onClick}>
        {buttonTitle}
        <div className="divider" />
        <ChevronRight color={theme.colors.deepGreen} />
      </span>
    </div>
  )
}

export default LabelWithButton
