import { Button, Card, Col, Dropdown, Menu, Row } from 'antd'
import { threeDotSVG } from 'assets/svg/threeDot'
import IconButton from 'components/button/IconButton'
import { SelfServePhotoSchema } from 'models/self-serve-commissioning'
import { theme } from 'theme'

interface SelfServeInfoCardProps {
  image?: SelfServePhotoSchema
  title?: string
  subtitles: string[]
  options: {
    onClick: () => void
    title: string
  }[]
  body: {
    title: string | JSX.Element
    buttonText: string
    onClick: () => void
  }
}

const SelfServeInfoCard: React.FC<SelfServeInfoCardProps> = ({ image, title, subtitles, options, body, children }) => {
  return (
    <Card
      style={{
        borderRadius: '8px',
        marginTop: 20,
        boxShadow: '0px 4px 17px 0px #00000012',
      }}
      bodyStyle={{
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Row gutter={[10, 10]}>
        <Col span={4} style={{ overflow: 'hidden' }}>
          <img
            alt={title}
            src={image?.urlThumb}
            style={{ width: '100%', aspectRatio: '1 / 1', borderRadius: '8px', objectFit: 'cover' }}
          />
        </Col>
        <Col style={{ flex: 1 }}>
          <span className="heading-05-bold" style={{ marginBottom: '8px' }}>
            {title}
          </span>
          {subtitles.map((subtitle) => (
            <p className="paragraph-01-regular" style={{ color: theme.colors.darkGray, margin: 0, marginBottom: 8 }}>
              {subtitle}
            </p>
          ))}
        </Col>
        <Dropdown
          overlay={
            <Menu className="table-dot-dropdown">
              {options.map((option) => (
                <Menu.Item onClick={option.onClick}>
                  <p className="paragraph-02-regular">{option.title}</p>
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <IconButton
            onClick={(e) => {
              e.preventDefault()
            }}
            icon={threeDotSVG}
          />
        </Dropdown>
      </Row>
      <Col
        style={{
          padding: '8px 16px',
          backgroundColor: theme.colors.grayBackground,
          borderRadius: '8px',
          marginTop: 10,
        }}
      >
        <Row justify="space-between" align="middle" style={{ flex: 1 }}>
          <span className="heading-06-regular">{body.title}</span>
          <Button onClick={body.onClick} style={{ backgroundColor: 'transparent' }} className="button-cap-03">
            {body.buttonText}
          </Button>
        </Row>
        {children}
      </Col>
    </Card>
  )
}

export default SelfServeInfoCard
