import CoreButton, { CoreButtonProps } from './CoreButton'
import styled from 'styled-components'

export const PrimaryButtonWrapper = styled.div`
  .ant-btn-primary:hover {
    background-color: ${(props) => props.theme.colors.buttonHovering};
    border-color: ${(props) => props.theme.colors.buttonHovering};
  }
  .ant-btn-primary > a:hover {
    color: ${(props) => props.theme.colors.black};
    text-shadow: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
  .ant-btn {
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    height: 38px;
    background: ${(props) => props.theme.colors.primary};
    box-shadow: none;
    border: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-btn > svg {
    margin-bottom: 2px;
  }

  .ant-btn > span {
    color: ${(props) => props.theme.colors.black};
    text-shadow: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-btn-general {
    border-radius: 20px;
    text-transform: uppercase;
  }
`

const PrimaryButton: React.FC<CoreButtonProps> = ({ ...props }) => {
  return (
    <PrimaryButtonWrapper>
      <CoreButton {...props} type="primary" className="regular-cap-02" />
    </PrimaryButtonWrapper>
  )
}

export default PrimaryButton
