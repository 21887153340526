import { NetworkFormProps } from 'components/form/projects/NetworkForm'
import { SelfServeImageType, SelfServeNetworkSchemaForm, SelfServePhotoSchema } from 'models/self-serve-commissioning'
import { useEffect, useState } from 'react'

const useNetworkForm = ({
  form,
  initialValues,
  initialSiteInfo,
  createOrGetSite,
  updateInstallation,
  setUserInputNetworkFields,
  goToNextStep,
}: NetworkFormProps) => {
  const typeOfNetworks = [
    { value: 'swtch', label: 'SWTCH Communication Hardware' },
    { value: 'building_network', label: 'Building Network' },
    { value: 'cellular', label: 'Cellular' },
    { value: 'non_networked', label: 'Non-networked' },
  ]

  const [openTypeOfNetworkHelp, setOpenTypeOfNetworkHelp] = useState(false)
  const [openGuideLines, setOpenGuideLines] = useState(false)

  const [currentInitialValues, setCurrentInitialValues] = useState<SelfServeNetworkSchemaForm>()
  const [initialValuesLoading, setInitialValuesLoading] = useState(true)

  useEffect(() => {
    let loadedInitialValues = {}
    if (initialValues) {
      loadedInitialValues = {
        ...initialValues,
        router: initialValues?.photos?.filter((photo) => photo?.type === 'router'),
        poe: initialValues?.photos?.filter((photo) => photo?.type === 'poe'),
      }
    }
    if (initialSiteInfo) {
      loadedInitialValues = {
        ...loadedInitialValues,
        site: initialSiteInfo?.address,
      }
    }
    setUserInputNetworkFields(loadedInitialValues)
    setCurrentInitialValues(loadedInitialValues)
    setInitialValuesLoading(false)
  }, [initialValues, initialSiteInfo])

  useEffect(() => {
    if (!initialValuesLoading) {
      //Set the form's fields into the initial state
      form.resetFields()
    }
  }, [initialValuesLoading])

  const handleTypeOfNetworkHelp = () => setOpenTypeOfNetworkHelp(!openTypeOfNetworkHelp)
  const handleGuideLines = () => setOpenGuideLines(!openGuideLines)

  const handleSelect = async (site: string) => {
    if (site) {
      await createOrGetSite(site)
    }
    form.setFieldsValue({ site })
    handleValuesChange(undefined, { ...currentInitialValues, site })
  }

  const handleImageUpload = (type: SelfServeImageType) => (images: SelfServePhotoSchema[]) => {
    form.setFieldsValue({ [type]: images })
    handleValuesChange(undefined, { ...currentInitialValues, [type]: images })
  }

  const handleValuesChange = (_: any, allValues: SelfServeNetworkSchemaForm) => {
    const id = currentInitialValues?.id
    setCurrentInitialValues({ id, ...allValues })
    setUserInputNetworkFields({ id, ...allValues })
  }

  const onFinish = (values: SelfServeNetworkSchemaForm) => {
    const { router, poe, site, ...rest } = values
    const id = currentInitialValues?.id
    updateInstallation(
      [
        {
          id,
          ...rest,
          photos: [...(router || []), ...(poe || [])],
        },
      ],
      goToNextStep,
    )
  }

  return {
    typeOfNetworks,
    openTypeOfNetworkHelp,
    openGuideLines,
    initialValuesLoading,
    currentInitialValues,
    handleTypeOfNetworkHelp,
    handleGuideLines,
    handleSelect,
    handleImageUpload,
    handleValuesChange,
    onFinish,
  }
}

export default useNetworkForm
