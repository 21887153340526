import { Dropdown, Menu, Row } from 'antd'
import { threeDotSVG } from 'assets/svg/threeDot'
import IconButton from 'components/button/IconButton'
import { theme } from 'theme'

interface SelfServeBreakerInfoCardProps {
  title: string
  options: {
    onClick: () => void
    title: string
  }[]
}

const SelfServeBreakerInfoCard: React.FC<SelfServeBreakerInfoCardProps> = ({ title, options }) => {
  return (
    <div
      style={{
        width: '100%',
        marginTop: 10,
        backgroundColor: theme.colors.white,
        borderRadius: '48px',
        padding: '4px 16px',
      }}
    >
      <Row align="middle" justify="space-between">
        <span className="button-cap-03">{title}</span>
        <Dropdown
          overlay={
            <Menu className="table-dot-dropdown">
              {options.map(({ onClick, title: optionTitle }) => (
                <Menu.Item onClick={onClick}>
                  <p className="paragraph-02-regular">{optionTitle}</p>
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <IconButton onClick={(e) => e.preventDefault()} icon={threeDotSVG} />
        </Dropdown>
      </Row>
    </div>
  )
}

export default SelfServeBreakerInfoCard
