import { theme } from 'theme'

export const warningSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
    <path
      fill={theme.colors.warningPrimary}
      d="M7 0a7 7 0 100 14.001A7 7 0 007 0zm-.5 3.625c0-.069.056-.125.125-.125h.75c.069 0 .125.056.125.125v4.25A.125.125 0 017.375 8h-.75a.125.125 0 01-.125-.125v-4.25zM7 10.5A.75.75 0 017 9a.75.75 0 010 1.5z"
    ></path>
  </svg>
)
