import { Form, FormInstance, FormProps, Input } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import { styleDisplay } from 'atom/style/display'
import { requiredRule } from 'helpers/rules'
import { SelfServeUserFormData } from 'models/self-serve-commissioning'

interface SaveInfoFormProps extends FormProps {
  form: FormInstance<any>
  onFinish: (values: SelfServeUserFormData) => void
}

const SaveInfoForm: React.FC<SaveInfoFormProps> = ({ form, onFinish, ...props }) => {
  return (
    <Form form={form} layout="vertical" requiredMark="optional" name="info-form" onFinish={onFinish} {...props}>
      <p className="heading-03-bold">{'Info'}</p>
      <Form.Item name="name" label="Your Name" rules={[requiredRule('Name')]} style={styleDisplay(100)}>
        <Input placeholder="Your Name" />
      </Form.Item>
      <Form.Item name="company" label="Company" rules={[requiredRule('Company')]} style={styleDisplay(100)}>
        <Input placeholder="Company" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          requiredRule('Phone Number'),
          { pattern: /^\+\d{1} \d{3}-\d{3}-\d{4}$/, message: 'Invalid phone number format!' },
        ]}
        style={styleDisplay(100)}
      >
        <MaskedInput inputMode="numeric" mask="+0 000-000-0000" placeholder="+1 234-567-9000" />
      </Form.Item>
    </Form>
  )
}

export default SaveInfoForm
