import React, { ReactElement, useState } from 'react'
import { Navigation } from './navigation'
import { AppHeader } from './app-header'
import { AppFooter } from './app-footer'

import { Layout } from 'antd'
import { useAppState } from 'state'
import { isMobileFullView } from 'helpers/routes'
import { useLocation } from 'react-router'

const { Content } = Layout

export interface AuthenticatedLayoutChildProps {
  showProfile: boolean
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>
}

interface AuthenticatedLayoutProps {
  children: ReactElement<AuthenticatedLayoutChildProps>
}

// AuthenticatedLayout component
export const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true)
  const [showProfile, setShowProfile] = useState(false) //Only visible in Projects route

  // Return JSX for the AuthenticatedLayout component
  const { IsMobile } = useAppState()
  const location = useLocation()
  const isMobile = IsMobile()
  const mobileFullView = isMobileFullView(location.pathname, isMobile)

  return (
    <Layout>
      <Navigation collapsed={collapsed} handleToggleMenu={() => setCollapsed(!collapsed)} />
      <Layout>
        <AppHeader
          collapsed={collapsed}
          handleToggleMenu={() => setCollapsed(!collapsed)}
          setShowProfile={setShowProfile}
        />
        <Content className={mobileFullView ? 'no-gap full-mobile-view' : ''} style={{ padding: '8px' }}>
          {React.isValidElement(children) ? React.cloneElement(children, { showProfile, setShowProfile }) : children}
        </Content>
        <AppFooter />
      </Layout>
    </Layout>
  )
}
