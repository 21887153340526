// Import the `compile` function from the 'path-to-regexp' library
import { compile } from 'path-to-regexp'

// Define paths for various routes in your application
export const Paths = {
  auth0login: '/connect',
  auth0Callback: '/auth0/callback',
  onboard: '/onboard',
  dashboard: '/dashboard',
  panelOverview: '/panel-overview',
  tenant: '/panel-overview/:selectedTenantId',
  newTenant: '/tenant',
  template: '/template',
  chargerModels: '/charger-models',
  projects: '/projects',
}

// Compile the path strings into functions for generating URLs
export const Links = {
  auth0login: compile(Paths.auth0login),
  auth0Callback: compile(Paths.auth0Callback),
  onboard: compile(Paths.onboard),
  dashboard: compile(Paths.dashboard),
  panelOverview: compile(Paths.panelOverview),
  tenant: compile(Paths.tenant),
  newTenant: compile(Paths.newTenant),
  template: compile(Paths.template),
  chargerModels: compile(Paths.chargerModels),
  projects: compile(Paths.projects),
}
