import { theme } from 'theme'

export const installerStatus = {
  in_progress: { backgroundColor: theme.colors.cultered, color: theme.colors.softBlack, text: 'In Progress' },
  assistance_required: {
    backgroundColor: theme.colors.dangerSecondary,
    color: theme.colors.dangerPrimary,
    text: 'Assistance Required',
  },
  complete: { backgroundColor: theme.colors.successSecondary, color: theme.colors.successPrimary, text: 'Complete' },
}
