import styled from 'styled-components'

export interface SuffixButtonProps {
  onClick: () => void
}

const StyledSuffixButton = styled.div`
  margin-right: 8px;
  display: flex;
  cursor: pointer;
`

const SuffixButton: React.FC<SuffixButtonProps> = ({ onClick, children }) => {
  return <StyledSuffixButton onClick={onClick}>{children}</StyledSuffixButton>
}

export default SuffixButton
