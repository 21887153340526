import { theme } from 'theme'

export const errorIconSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
    <path
      fill={theme.colors.dangerPrimary}
      d="M7 0a7 7 0 10.001 14.001A7 7 0 007 0zm2.584 9.66l-1.03-.005L7 7.803l-1.552 1.85-1.032.005a.124.124 0 01-.125-.125c0-.03.01-.058.03-.081L6.352 7.03 4.32 4.61a.125.125 0 01.095-.206l1.033.004L7 6.259l1.552-1.85 1.03-.004c.07 0 .126.054.126.125a.13.13 0 01-.03.08l-2.03 2.421L9.68 9.453a.125.125 0 01-.095.206z"
    ></path>
  </svg>
)
