import {
  NonNullableSelfServeUserRef,
  SelfServeChargerConnectivityRef,
  SelfServeImageRef,
  SelfServeInstallationDetailRef,
  SelfServeInstallationListRef,
  SelfServeInstallationRef,
  SelfServeSiteSchema,
} from 'models/self-serve-commissioning'

export const mockSelfServeUserData: NonNullableSelfServeUserRef = {
  status: 'success',
  data: {
    id: '1',
    firstName: 'Swtch',
    lastName: 'Installer',
    company: 'SWTCH Energy Inc.',
    email: 'installer@swtchenergy.com',
    phone: '1231231233',
  },
}

export const mockSelfServeImageResponse: SelfServeImageRef = {
  status: 'success',
  data: {
    id: '1729090777759',
    url: 'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg',
    urlThumb: 'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg',
  },
}

const mockSiteInformation: SelfServeSiteSchema = {
  id: '1',
  address: '123',
  createdAt: '2024-10-21',
  updatedBy: 'userId',
  updatedAt: '2024-10-21',
}

export const mockSelfServeInstallations: SelfServeInstallationListRef = {
  status: 'success',
  data: [
    {
      id: '1',
      status: 'complete',
      site: mockSiteInformation,
      createdAt: '2024-10-16T00:11:35.261000',
      updatedBy: 'str',
      updatedAt: '2024-10-16T00:11:35.261000',
    },
  ],
}

export const mockSelfServeInstallationDetail: SelfServeInstallationDetailRef = {
  status: 'success',
  data: {
    id: '1',
    status: 'complete',
    site: mockSiteInformation,
    createdAt: '2024-10-16T00:11:35.261000',
    updatedBy: 'str',
    updatedAt: '2024-10-16T00:11:35.261000',
  },
}

export const mockCreateSelfServeInstallation: SelfServeInstallationRef = {
  status: 'success',
  data: {
    id: '1',
    status: 'in_progress',
    site: mockSiteInformation,
    createdAt: '2024-10-16T00:11:35.261000',
    updatedBy: 'str',
    updatedAt: '2024-10-16T00:11:35.261000',
  },
}

export const mockUpdateSelfServeInstallation: SelfServeInstallationDetailRef = {
  status: 'success',
  data: {
    id: '1',
    status: 'in_progress',
    site: mockSiteInformation,
    createdAt: '2024-10-16T00:11:35.261000',
    updatedBy: 'str',
    updatedAt: '2024-10-16T00:11:35.261000',
  },
}

export const mockGetSelfServeChargerConnectivity: SelfServeChargerConnectivityRef = {
  status: 'success',
  data: [
    {
      serialNumber: '123',
      connectionStatus: 'connected',
      connectivityStatus: 'acceptable',
      signalStrength: 0.2,
    },
  ],
}
