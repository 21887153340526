import { theme } from 'theme'

export const ChevronRight = ({ color = theme.colors.softBlackHover }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" fill="none" viewBox="0 0 6 11">
    <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1.5l4 4-4 4"></path>
  </svg>
)

export const ChevronUp = ({ color = theme.colors.softBlackHover }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none" viewBox="0 0 10 6">
    <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 5 4-4 4 4"></path>
  </svg>
)

export const ChevronDown = ({ color = theme.colors.softBlackHover }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none" viewBox="0 0 10 6">
    <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1 5 5 1 1"></path>
  </svg>
)
