import styled from 'styled-components'
import CoreButton, { CoreButtonProps } from './CoreButton'

export const SecondaryButtonWrapper = styled.div`
  .ant-btn-ghost:hover {
    background-color: ${(props) => props.theme.colors.white};
    border-color: ${(props) => props.theme.colors.primary};
    > span {
      color: ${(props) => props.theme.colors.primary};
    }
    > svg > path {
      stroke: ${(props) => props.theme.colors.primary};
    }
  }
  .ant-btn-ghost > a:hover {
    color: ${(props) => props.theme.colors.primary};
    text-shadow: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
  .ant-btn {
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    height: 38px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: none;
    border: ${(props) => `1px solid ${props.theme.colors.grayScale}`};
    font-family: ${(props) => props.theme.fontFamily.regular};
    text-transform: uppercase;
  }

  .ant-btn > svg {
    margin-bottom: 2px;
  }

  .ant-btn > span {
    color: ${(props) => props.theme.colors.black};
    text-shadow: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
`

const SecondaryButton: React.FC<CoreButtonProps> = ({ ...props }) => {
  return (
    <SecondaryButtonWrapper>
      <CoreButton {...props} type="ghost" className="button-cap-02" />
    </SecondaryButtonWrapper>
  )
}

export default SecondaryButton
