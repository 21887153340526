import { Button, ButtonProps } from 'antd'

export interface CoreButtonProps extends ButtonProps {
  title?: string
}

const CoreButton: React.FC<CoreButtonProps> = ({ title, ...props }) => {
  return <Button {...props}>{title}</Button>
}

export default CoreButton
