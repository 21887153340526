import { theme } from 'theme'

export const threeDotSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" fill="none" viewBox="0 0 4 16">
    <path
      stroke={theme.colors.black}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M2 2.75a.75.75 0 110-1.5.75.75 0 010 1.5zm0 6a.75.75 0 110-1.5.75.75 0 010 1.5zm0 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
    ></path>
  </svg>
)
