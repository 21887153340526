import { Button, Modal } from 'antd'
import { filledCircleErrorSVG } from '../../../../assets/svg/error'
import { styled, theme } from '../../../../theme'
import { Circuit } from '../../../../models/circuit'

export const CustomFooterLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  .ant-btn-ghost {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    border-color: ${theme.colors.lightGray};
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
    width: 175px;
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 0px !important;
  }

  .ant-btn-ghost:hover {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.lightGray};
  }

  .ant-btn-primary {
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    width: 175px;
    margin-left: 0px;
  }

  .ant-btn-primary:hover {
    background-color: ${theme.colors.buttonHovering};
    border-color: ${theme.colors.buttonHovering};
  }

  .red {
    background-color: ${theme.colors.dangerPrimary};
    border-color: ${theme.colors.dangerPrimary};
    color: ${theme.colors.white};

    :hover,
    :focus {
      background-color: ${theme.colors.dangerPrimary};
      border-color: ${theme.colors.dangerPrimary};
      color: ${theme.colors.white};
    }
  }

  .ant-btn-ghost {
    width: 50%;
  }
`

interface props {
  visible: boolean
  tenantRef: {
    id: string
    name: string
  }
  circuits: Circuit[]
  onCancel: () => void
  updateCircuits: (
    tenantRef: {
      id: string
      name: string
    },
    circuitData: Circuit[],
  ) => void
  setLoading: (loading: boolean) => void
}

export const UpdateEndpointModal: React.FC<props> = ({
  visible,
  tenantRef,
  circuits,
  onCancel,
  updateCircuits,
  setLoading,
}) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={400}
      footer={null}
      title={null}
      closable={false}
      centered={true}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>{filledCircleErrorSVG}</div>
        <div style={{ fontSize: '18px', fontWeight: 700, marginLeft: '12px' }}>Saving will update the endpoint.</div>
      </div>
      <div className="paragraph-01-regular" style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        Do you want to proceed?
      </div>
      <CustomFooterLayout>
        <Button type="ghost" onClick={onCancel}>
          CANCEL
        </Button>
        <Button
          className="red"
          type="primary"
          onClick={() => {
            setLoading(true)
            updateCircuits(tenantRef, circuits)
            onCancel()
          }}
          style={{ marginLeft: '12px' }}
        >
          CONTINUE
        </Button>
      </CustomFooterLayout>
    </Modal>
  )
}
