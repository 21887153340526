import { Row } from 'antd'
import { ChevronDown, ChevronUp } from 'assets/svg/chevron'
import { useState } from 'react'
import { theme } from 'theme'

interface CoreCollapseProps {
  title: string
}

const CoreCollapse: React.FC<CoreCollapseProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen(!isOpen)
  return (
    <>
      <Row
        align="middle"
        onClick={handleOpen}
        justify="space-between"
        style={{
          cursor: 'pointer',
          backgroundColor: theme.colors.grayBackground,
          padding: 16,
          borderRadius: 15,
          borderBottomLeftRadius: isOpen ? 0 : 15,
          borderBottomRightRadius: isOpen ? 0 : 15,
          marginTop: 20,
        }}
      >
        <span className="heading-05-regular">{title}</span>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </Row>
      {isOpen && children}
    </>
  )
}

export default CoreCollapse
