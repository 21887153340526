import { useAppState } from 'state'
import CoreModal, { CoreModalProps } from './CoreModal'
interface MobileFullModalProps {
  isModalOpen: boolean
}

const MobileFullModal: React.FC<CoreModalProps & MobileFullModalProps> = ({ isModalOpen, ...props }) => {
  const { IsMobile } = useAppState()

  const isMobile = IsMobile()
  return isModalOpen ? (
    <CoreModal
      {...props}
      isModalOpen
      className={isMobile ? `mobile-full ${props?.title ? '' : 'mobile-no-title'}` : props?.title ? '' : 'no-title'}
    />
  ) : null
}

export default MobileFullModal
