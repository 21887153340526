import { AddPanelFormProps } from 'components/form/projects/AddPanelForm'
import { SelfServePanelSchema, SelfServePhotoSchema } from 'models/self-serve-commissioning'
import { useEffect, useState } from 'react'

const useAddPanelForm = ({ form, handleModalOpen, initialValues, updateInstallation }: AddPanelFormProps) => {
  const [currentInitialValues, setCurrentInitialValues] = useState<SelfServePanelSchema>()
  const [initialValuesLoading, setInitialValuesLoading] = useState(true)

  const typeOfPanel = [
    { value: 'Dedicated', label: 'Dedicated Panel' },
    { value: 'Mixed', label: 'Mixed Panel' },
  ]

  useEffect(() => {
    let loadedInitialValues = {}
    if (initialValues) {
      loadedInitialValues = {
        ...initialValues,
      }
    }
    setCurrentInitialValues(loadedInitialValues)
    setInitialValuesLoading(false)
  }, [initialValues])

  useEffect(() => {
    if (!initialValuesLoading) {
      form.resetFields()
    }
  }, [initialValuesLoading])

  const handleValuesChange = (_: any, allValues: SelfServePanelSchema) => {
    setCurrentInitialValues(allValues)
  }

  const handleImageUpload = (photos: SelfServePhotoSchema[]) => {
    form.setFieldsValue({ photos })
    handleValuesChange(undefined, { ...currentInitialValues, photos })
  }

  const onFinish = (values: SelfServePanelSchema) => {
    updateInstallation(values, initialValues, handleModalOpen)
  }

  return { currentInitialValues, typeOfPanel, handleValuesChange, handleImageUpload, onFinish }
}

export default useAddPanelForm
