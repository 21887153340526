import { Button, Modal, ModalProps, Progress } from 'antd'

import { ApiError } from 'models/error'
import { AlertError } from 'components/error'
import { useAppState } from 'state'
import { ReactNode } from 'react'

export interface CoreModalProps extends ModalProps {
  title?: string | ReactNode
  saveTitle: string
  isModalOpen: boolean
  onClose?: () => void
  onCancel?: () => void
  onFinish: () => void
  onOk?: () => void
  loading?: boolean
  error?: ApiError
  className?: string
  progress?: number
  cancelText?: string
  hideFooter?: boolean
  disableSubmit?: boolean
}

const CoreModal: React.FC<CoreModalProps> = ({
  title,
  saveTitle,
  isModalOpen,
  onClose,
  onCancel,
  onFinish,
  onOk,
  loading,
  error,
  className,
  children,
  progress,
  cancelText = 'Cancel',
  hideFooter = false,
  disableSubmit = false,
  ...props
}) => {
  const { IsMobile } = useAppState()

  const isMobile = IsMobile()

  const buttonProps = {
    style: { width: !onCancel && isMobile ? '100%' : isMobile ? 'fit-content' : '49%' },
  }

  return (
    <Modal
      title={title}
      visible={isModalOpen}
      onCancel={onClose}
      width={800}
      onOk={onOk}
      closable={!!onClose}
      style={{ top: 5 }}
      className={className}
      footer={
        hideFooter
          ? null
          : [
              onCancel && (
                <Button key="back" onClick={onCancel} loading={loading} disabled={loading} {...buttonProps}>
                  {cancelText}
                </Button>
              ),
              <Button
                key="submit"
                type="primary"
                onClick={onFinish}
                loading={loading}
                disabled={loading || disableSubmit}
                {...buttonProps}
              >
                {saveTitle}
              </Button>,
            ]
      }
      {...props}
    >
      <AlertError error={error} />
      {progress !== undefined && (
        <Progress percent={progress} showInfo={false} className={`below-header ${isMobile ? 'mobile-header' : ''}`} />
      )}
      <div className={isMobile ? 'mobile-modal-body' : ''}>{children}</div>
    </Modal>
  )
}

export default CoreModal
