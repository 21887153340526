import { errorIconSVG } from 'assets/svg/errorIcon'
import { theme } from 'theme'

export interface ErrorCardProps {
  description: string
}

const ErrorCard: React.FC<ErrorCardProps> = ({ description }) => {
  return (
    <div
      style={{
        borderRadius: '6px',
        display: 'flex',
        padding: '8px 16px',
        marginBottom: 20,
        backgroundColor: theme.colors.ErrorPrimary,
      }}
    >
      {errorIconSVG}
      <div style={{ flex: 1, marginLeft: '16px' }}>
        <p className="paragraph-01-regular" style={{ marginBottom: 0 }}>
          {description}
        </p>
      </div>
    </div>
  )
}

export default ErrorCard
