import { Col } from 'antd'

const CompletedForm: React.FC = () => {
  return (
    <Col>
      <img
        alt="Completed new site"
        style={{ width: '100%', marginBottom: 32 }}
        src={'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg'}
      />
      {/* Replace later with actual image*/}
      <p className="heading-03-bold">{'Completed'}</p>
      <p className="heading-06-regular">
        {'The chargers are now properly installed. SWTCH will work with the property to activate these chargers.'}
      </p>
      <p className="heading-06-regular">{'Thanks for installing with SWTCH!'}</p>
    </Col>
  )
}

export default CompletedForm
