import { Form } from 'antd'
import AddPanelForm from 'components/form/projects/AddPanelForm'
import MobileBottomModal from 'components/modal/MobileBottomModal'
import { SelfServePanelSchema } from 'models/self-serve-commissioning'
import { useAppState } from 'state'

interface AddPanelModalProps {
  isModalOpen: boolean
  handleModalOpen: () => void
  initialValues?: SelfServePanelSchema
  updateInstallation: (
    request: SelfServePanelSchema,
    existingPanel?: SelfServePanelSchema,
    callBack?: () => void,
  ) => void
}

const AddPanelModal: React.FC<AddPanelModalProps> = ({
  isModalOpen,
  handleModalOpen,
  initialValues,
  updateInstallation,
}) => {
  const { IsMobile } = useAppState()
  const isMobile = IsMobile()
  const [form] = Form.useForm()

  return (
    <MobileBottomModal
      isModalOpen={isModalOpen}
      centered={!isMobile}
      saveTitle="Save"
      onClose={handleModalOpen}
      onFinish={form.submit}
      title={'Add a Panel'}
      onCancel={handleModalOpen}
      destroyOnClose
    >
      <AddPanelForm
        form={form}
        handleModalOpen={handleModalOpen}
        initialValues={initialValues}
        updateInstallation={updateInstallation}
      />
    </MobileBottomModal>
  )
}

export default AddPanelModal
