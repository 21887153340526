import { PanelListFormProps } from 'components/form/projects/PanelListForm'
import { SelfServeBreakerSchema, SelfServePanelSchema } from 'models/self-serve-commissioning'
import { useState } from 'react'

const usePanelListForm = ({ initialValues, updateInstallation }: PanelListFormProps) => {
  const [openAddPanel, setOpenAddPanel] = useState(false)
  const [openAddBreaker, setOpenAddBreaker] = useState(false)
  const [selectedPanel, setSelectedPanel] = useState<SelfServePanelSchema>()
  const [selectedBreakerIndex, setSelectedBreakerIndex] = useState<number>(-1)
  const handleAddPanel = (panel?: SelfServePanelSchema) => () => {
    setSelectedPanel(panel)
    setOpenAddPanel(!openAddPanel)
  }
  const handleAddBreaker = (panel: SelfServePanelSchema | undefined, index: number) => () => {
    setSelectedPanel(panel)
    setSelectedBreakerIndex(index)
    setOpenAddBreaker(!openAddBreaker)
  }

  const updatePanel = (
    updatedPanelRequest: SelfServePanelSchema,
    existingPanel?: SelfServePanelSchema,
    callBack?: () => void,
  ) => {
    let updatedPanel = [...(initialValues || [])]
    const updatedPanelIndex = updatedPanel?.findIndex((currentPanel) => currentPanel?.id === existingPanel?.id)
    if (updatedPanelIndex > -1) {
      updatedPanel[updatedPanelIndex] = {
        ...updatedPanel[updatedPanelIndex],
        ...updatedPanelRequest,
      }
    } else {
      updatedPanel.push(updatedPanelRequest)
    }
    updateInstallation(updatedPanel, callBack)
  }

  const deletePanel = (panel: SelfServePanelSchema) => () => {
    let updatedPanel = [...(initialValues || [])]
    const updatedPanelIndex = updatedPanel?.findIndex((currentPanel) => currentPanel?.id === panel?.id)
    updatedPanel.splice(updatedPanelIndex, 1)
    updateInstallation(updatedPanel)
  }

  const updateBreaker = (updatedBreakerRequest: SelfServeBreakerSchema, callBack?: () => void) => {
    let breakers = [...(selectedPanel?.breakers || [])]
    if (selectedBreakerIndex > -1) {
      breakers[selectedBreakerIndex] = updatedBreakerRequest
    } else {
      breakers.push(updatedBreakerRequest)
    }
    updatePanel({ ...selectedPanel, breakers }, selectedPanel, callBack)
  }

  const deleteBreaker = (panel: SelfServePanelSchema, index: number) => () => {
    let breakers = panel?.breakers || []
    breakers.splice(index, 1)
    updatePanel({ ...panel, breakers }, panel)
  }

  const panelModifyOptions = (panel: SelfServePanelSchema) => [
    {
      onClick: handleAddPanel(panel),
      title: 'Edit',
    },
    {
      onClick: deletePanel(panel),
      title: 'Delete',
    },
  ]

  const breakerModifyOptions = (panel: SelfServePanelSchema, index: number) => [
    {
      onClick: handleAddBreaker(panel, index),
      title: 'Edit',
    },
    {
      onClick: deleteBreaker(panel, index),
      title: 'Delete',
    },
  ]

  return {
    openAddPanel,
    openAddBreaker,
    selectedPanel,
    selectedBreakerIndex,
    handleAddPanel,
    handleAddBreaker,
    updatePanel,
    updateBreaker,
    panelModifyOptions,
    breakerModifyOptions,
  }
}

export default usePanelListForm
