import { Button, Form, Input, Switch, Table } from 'antd'
import { Branch, BranchData, Circuit, EndPointData, PanelType } from '../../../../models/circuit'
import { BranchHeaderWrapper, EditBranchButtonWrapper } from '../../../../atom/load-management/load-management'
import { blackEditSVG } from '../../../../assets/svg/edit'
import { trashCanSVG } from '../../../../assets/svg/trashCan'
import { useEffect, useState } from 'react'
import { useCircuits } from '../../../../hooks/useCircuits'
import { MustBeNumerical, MustBeRequired } from '../../../../components/rules/rules'
import { SvgSpacing } from '../../../../atom/panel-overview'
import { ButtonLink } from '../../../../atom/button'
import { ChargerTable } from './charger-table'
import { CustomInputNumber } from '../../../../components/input-number'
import { LoadManagementCharger } from '../../../../models/charger'
import { BranchTableWrapper, RemoveButtonWrapper } from '../../../../atom/load-management/branch-table'
import { useSwtchControlPermissions } from '../../../panel-overview/useSwtchControlPermissions'

interface props {
  branch: Branch
  circuit: Circuit
  circuits: Circuit[]
  breakerSizeLimit: number
  verifiedChargers: LoadManagementCharger[]
  endPoints: EndPointData[]
  saveClicked: boolean
  setVerifiedChargers: (chargers: LoadManagementCharger[]) => void
  setCircuits: (circuits: Circuit[]) => void
  setBreakerSizeLimit: (breakerSizeLimit: number) => void
  setInitialBreakerSizeLimit: (initialBreakerSizeLimit: boolean) => void
  setBreakerLimitWarning: (breakerLimitWarning: boolean) => void
  deleteBranch: (circuit: Circuit, branch: Branch, type: string) => void
  setDisableSaveData: (disableSaveData: boolean) => void
}

export const BranchTable: React.FC<props> = ({
  branch,
  circuit,
  circuits,
  breakerSizeLimit,
  verifiedChargers,
  endPoints,
  saveClicked,
  setVerifiedChargers,
  setCircuits,
  setBreakerSizeLimit,
  setInitialBreakerSizeLimit,
  setBreakerLimitWarning,
  deleteBranch,
  setDisableSaveData,
}) => {
  const [branchData, setBranchData] = useState<BranchData[]>([])
  const [branchName, setBranchName] = useState('')
  const [editBranchName, setEditBranchName] = useState(false)
  const { limitBreakerSizeDedicated, getTotalBreakerSize } = useCircuits()
  const [form] = Form.useForm()
  const { canUpdateSwtchControl, canDeleteSwtchControl } = useSwtchControlPermissions()

  useEffect(() => {
    const tempBranchArr = {
      branch_name: branch.name,
      load_management: branch.load_management,
      breaker_size: branch.breaker_size,
      end_point: findEndPoint(circuit, branch),
    }
    setBranchName(branch.name)
    setBranchData([{ ...tempBranchArr }])
    if (branch.name === '') {
      setEditBranchName(true)
    }

    form.setFieldsValue({
      breaker_size: branch.breaker_size,
    })
  }, [circuits, branch, endPoints])

  const onChangeBranch = (e: any, circuit: Circuit, branch: any, key?: string) => {
    if (canUpdateSwtchControl) {
      const singleCircuit = { ...circuit }
      const singleBranch = { ...branch }

      if ((e === null || !e.target) && key) {
        if (key === 'load_management') {
          singleBranch[key] = !branch.load_management
        } else {
          if (e === null) {
            singleBranch[key] = 0
          } else {
            singleBranch[key] = +e
          }
        }
        const tempBranches = singleCircuit.branches.map((branch) => {
          if (branch.id === singleBranch.id) return singleBranch
          return branch
        })
        const tempCircuit = { ...singleCircuit, branches: tempBranches }

        // setting new breaker limit with change of breaker size
        if (key === 'breaker_size') {
          const breakerLimit = limitBreakerSizeDedicated(tempCircuit)
          setBreakerSizeLimit(breakerLimit)
          setInitialBreakerSizeLimit(true)
        }

        const tempCircuits = circuits.map((circuitData) => {
          if (circuitData.id === tempCircuit.id) return tempCircuit
          return circuitData
        })
        setCircuits([...tempCircuits])
      } else {
        const name = e.target.name
        singleBranch[name] = e.target.value

        const tempBranches = singleCircuit.branches.map((branch) => {
          if (branch.id === singleBranch.id) return singleBranch
          return branch
        })
        const tempCircuit = { ...singleCircuit, branches: tempBranches }
        const tempCircuits = circuits.map((dedicatedCircuit) => {
          if (dedicatedCircuit.id === tempCircuit.id) return tempCircuit
          return dedicatedCircuit
        })
        setCircuits([...tempCircuits])
      }
    }
  }

  const findEndPoint = (circuit: Circuit, branch: Branch): string => {
    const foundEndPoint = endPoints.find((endPointData) => {
      if (circuit.type === PanelType.dedicated) {
        if (endPointData.circuit === circuit.id && endPointData.branch === branch.id) {
          return true
        }
        if (circuit.two_stage && endPointData.circuit === circuit.id) {
          return true
        }
        return false // No match
      }
      // For non-dedicated circuit types
      return endPointData.circuit === circuit.id
    })

    // Early exit if no endpoint was found
    if (!foundEndPoint) return ''

    return foundEndPoint.url
  }

  const columns = [
    {
      title: (
        <BranchHeaderWrapper>
          {branchName.length === 0 ? <span>Circuit Name</span> : <span>{branch.name}</span>}{' '}
          <EditBranchButtonWrapper>
            {canUpdateSwtchControl && (
              <Button shape="circle" onClick={() => setEditBranchName(!editBranchName)}>
                {blackEditSVG}
              </Button>
            )}
          </EditBranchButtonWrapper>
        </BranchHeaderWrapper>
      ),
      dataIndex: 'branch_name',
      key: 'branch_name',
      render: (branch_name: string) => {
        return editBranchName ? (
          <>
            <Input
              placeholder="Use breaker number as circuit name"
              name="name"
              value={branch.name}
              onChange={(value) => onChangeBranch(value, circuit, branch)}
            />
          </>
        ) : (
          <>{branch.name}</>
        )
      },
    },
    {
      title: <div>Load Management</div>,
      dataIndex: 'load_management',
      key: 'load_management',
      render: (load_management: boolean) => {
        return (
          <Form>
            <Form.Item rules={[{ required: true }]}>
              <Switch
                style={{ backgroundColor: branch.load_management ? '#30D7BA' : '#bfbfbf', width: '36px' }}
                disabled={true}
                defaultChecked={branch.load_management}
                checked={branch.load_management}
                onChange={(value) => onChangeBranch(value, circuit, branch, 'load_management')}
              />
            </Form.Item>
          </Form>
        )
      },
    },
    {
      title: <div>Breaker Size (amp)</div>,
      dataIndex: 'breaker_size',
      render: (breaker_size: string) => {
        return (
          <Form form={form}>
            <Form.Item
              name="breaker_size"
              rules={[
                MustBeRequired('Please enter a positive integer'),
                MustBeNumerical,
                {
                  type: 'number',
                  max: breakerSizeLimit + +breaker_size,
                  warningOnly: true,
                  message:
                    breakerSizeLimit >= 0
                      ? `Breaker size cannot exceed: ${breakerSizeLimit} A`
                      : 'Existing load is too large',
                  validator: (_, value) => {
                    if (value > breakerSizeLimit + breaker_size) {
                      setBreakerLimitWarning(true)
                      return Promise.reject('Template is required!')
                    } else {
                      setBreakerLimitWarning(false)
                      return Promise.resolve()
                    }
                  },
                },
                {
                  type: 'number',
                  max: getTotalBreakerSize(circuit),
                  warningOnly: true,
                  message: `Total breaker size cannot exceed circuit size: ${circuit.circuit_size} A`,
                  validator: (_, value) => {
                    if (value > getTotalBreakerSize(circuit)) {
                      setBreakerLimitWarning(true)
                      return Promise.reject('Template is required!')
                    } else {
                      setBreakerLimitWarning(false)
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <CustomInputNumber
                defaultValue={breaker_size}
                value={breaker_size}
                onChange={(value) => onChangeBranch(value, circuit, branch, 'breaker_size')}
                // ampFormat={true}
              />
            </Form.Item>
          </Form>
        )
      },
    },
    {
      title: <div>End Point</div>,
      dataIndex: 'end_point',
      key: 'end_point',
    },
  ]

  return (
    <>
      {canDeleteSwtchControl && (
        <RemoveButtonWrapper>
          <ButtonLink
            icon={<SvgSpacing>{trashCanSVG}</SvgSpacing>}
            text="Remove Circuit"
            onClick={() => deleteBranch(circuit, branch, circuit.type)}
          />
        </RemoveButtonWrapper>
      )}
      <BranchTableWrapper>
        <Table bordered columns={columns} dataSource={branchData} pagination={false} />
      </BranchTableWrapper>
      <ChargerTable
        chargers={branch.chargers}
        circuits={circuits}
        setCircuits={setCircuits}
        circuit={circuit}
        branch={branch}
        verifiedChargers={verifiedChargers}
        saveClicked={saveClicked}
        setVerifiedChargers={setVerifiedChargers}
        setDisableSaveData={setDisableSaveData}
        setBreakerLimitWarning={setBreakerLimitWarning}
      />
    </>
  )
}
