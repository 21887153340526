import { Form, FormInstance, Input, Radio } from 'antd'
import { scanSVG } from 'assets/svg/scan'
import { InputWithSuffixWrapper } from 'atom/input'
import {
  SelfServeInsideRadioGroupWrapper,
  SelfServeRadioGroupWrapper,
  SelfServeRadioInnerGroupWrapper,
} from 'atom/projects/radio-group'
import { styleDisplay } from 'atom/style/display'
import SuffixButton from 'components/button/SuffixButton'
import LabelWithInfo from 'components/label/LabelWithInfo'
import MobileFullModal from 'components/modal/MobileFullModal'
import UploadImage from 'components/upload/UploadImage'
import { requiredRule } from 'helpers/rules'
import useAddChargerForm from 'hooks/projects/useAddChargerForm'
import { SelfServePanelChargerSchema, SelfServePanelSchema } from 'models/self-serve-commissioning'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { UploadSelfServeImage } from 'services/data-provider/self-serve-commissioning'

export interface AddChargerFormProps {
  form: FormInstance<any>
  panels?: SelfServePanelSchema[]
  handleModalOpen: () => void
  initialValues?: SelfServePanelChargerSchema
  updateInstallation: (
    request: SelfServePanelChargerSchema,
    existingCharger?: SelfServePanelChargerSchema,
    callBack?: () => void,
  ) => void
}

const AddChargerForm: React.FC<AddChargerFormProps> = ({
  form,
  handleModalOpen,
  panels,
  initialValues,
  updateInstallation,
}) => {
  const {
    currentInitialValues,
    typeOfConnectionPhase,
    openScanner,
    handleScanner,
    qrCodeScan,
    handleValuesChange,
    handleImageUpload,
    onFinish,
  } = useAddChargerForm({ form, handleModalOpen, initialValues, updateInstallation })

  return (
    <Form
      form={form}
      onValuesChange={handleValuesChange}
      layout="vertical"
      requiredMark={false}
      name="charger-form"
      onFinish={onFinish}
      initialValues={currentInitialValues}
    >
      <SelfServeRadioGroupWrapper>
        <InputWithSuffixWrapper>
          <MobileFullModal
            isModalOpen={openScanner}
            saveTitle="Close"
            destroyOnClose
            onClose={handleScanner}
            onFinish={handleScanner}
          >
            <BarcodeScannerComponent facingMode="environment" onUpdate={qrCodeScan} />
          </MobileFullModal>
          <Form.Item
            name="listingTitle"
            label={'Charger ID'}
            rules={[requiredRule('Charger ID')]}
            style={styleDisplay(100)}
          >
            <Input placeholder="Charger ID" suffix={<SuffixButton onClick={handleScanner}>{scanSVG}</SuffixButton>} />
          </Form.Item>
          <Form.Item
            name="serialNumber"
            label={<LabelWithInfo title="Charger S/N" onClick={() => {}} />}
            rules={[requiredRule('Charger S/N')]}
            style={styleDisplay(100)}
          >
            <Input placeholder="Charger S/N" />
          </Form.Item>
          <SelfServeRadioInnerGroupWrapper>
            <Form.Item
              name="panelLabel"
              label={<LabelWithInfo title="Panel" onClick={() => {}} />}
              rules={[requiredRule('Panel')]}
              style={styleDisplay(100)}
            >
              <Radio.Group>
                {panels?.map(({ label, breakers }) => (
                  <>
                    <Radio key={label} value={label}>
                      {label}
                    </Radio>
                    {currentInitialValues?.panelLabel === label && (
                      <SelfServeInsideRadioGroupWrapper>
                        <Form.Item
                          name="breakerNumber"
                          label={'Breaker'}
                          rules={[requiredRule('Breaker')]}
                          style={styleDisplay(100)}
                          className="heading-06-regular"
                        >
                          <Radio.Group>
                            {breakers?.map(({ breakerNumber }) => (
                              <Radio key={breakerNumber} value={breakerNumber}>
                                {breakerNumber}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </SelfServeInsideRadioGroupWrapper>
                    )}
                  </>
                ))}
              </Radio.Group>
            </Form.Item>
          </SelfServeRadioInnerGroupWrapper>
          <Form.Item name="connectionPhase" label={'Phase'} rules={[requiredRule('Phase')]} style={styleDisplay(100)}>
            <Radio.Group>
              {typeOfConnectionPhase.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="photos"
            label={'Photo of EV Charger'}
            rules={[requiredRule('Photo of EV Charger')]}
            style={styleDisplay(100)}
          >
            <UploadImage
              loadedImages={currentInitialValues?.photos || []}
              setLoadedImages={handleImageUpload}
              uploadFunction={UploadSelfServeImage}
              type="charger"
              singlePhoto
            />
          </Form.Item>
        </InputWithSuffixWrapper>
      </SelfServeRadioGroupWrapper>
    </Form>
  )
}

export default AddChargerForm
