import { Col, FormProps } from 'antd'
import { plusSVG } from 'assets/svg/plus'
import PrimaryButton from 'components/button/PrimaryButton'
import SelfServeBreakerInfoCard from 'components/card/SelfServeBreakerInfoCard'
import SelfServeInfoCard from 'components/card/SelfServeInfoCard'
import AddBreakerModal from 'feature/modal/projects/AddBreakerModal'
import AddPanelModal from 'feature/modal/projects/AddPanelModal'
import usePanelListForm from 'hooks/projects/usePanelListForm'
import { SelfServePanelSchema } from 'models/self-serve-commissioning'

export interface PanelListFormProps extends FormProps {
  initialValues?: SelfServePanelSchema[]
  updateInstallation: (request: SelfServePanelSchema[], callBack?: () => void) => Promise<void>
}

const PanelListForm: React.FC<PanelListFormProps> = ({ initialValues, updateInstallation }) => {
  const {
    openAddPanel,
    openAddBreaker,
    selectedPanel,
    selectedBreakerIndex,
    handleAddPanel,
    handleAddBreaker,
    updatePanel,
    updateBreaker,
    panelModifyOptions,
    breakerModifyOptions,
  } = usePanelListForm({ initialValues, updateInstallation })

  return (
    <>
      <AddPanelModal
        updateInstallation={updatePanel}
        isModalOpen={openAddPanel}
        handleModalOpen={handleAddPanel(undefined)}
        initialValues={selectedPanel}
      />
      <AddBreakerModal
        initialValues={selectedPanel?.breakers?.[selectedBreakerIndex]}
        updateInstallation={updateBreaker}
        isModalOpen={openAddBreaker}
        handleModalOpen={handleAddBreaker(undefined, -1)}
      />
      <Col>
        <p className="heading-03-bold">{'Panel'}</p>
        <p className="heading-06-regular">
          {'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.'}
        </p>
        <PrimaryButton onClick={handleAddPanel(undefined)} size="large" title={`Add a panel`} icon={plusSVG} />
        {initialValues?.map((panel) => (
          <SelfServeInfoCard
            image={panel?.photos?.[0]}
            title={panel?.label}
            subtitles={[`${panel?.type} Panel · ${panel?.ratedCapacity}A`, panel?.loc || '']}
            options={panelModifyOptions(panel)}
            body={{
              title: 'Breaker',
              buttonText: 'Add a Breaker',
              onClick: handleAddBreaker(panel, -1),
            }}
          >
            <Col>
              {panel?.breakers?.map((breaker, index) => (
                <SelfServeBreakerInfoCard
                  title={`${breaker.breakerNumber} · ${breaker.ratedCapacity}Amp`}
                  options={breakerModifyOptions(panel, index)}
                />
              ))}
            </Col>
          </SelfServeInfoCard>
        ))}
      </Col>
    </>
  )
}

export default PanelListForm
