import { theme } from 'theme'

// Defines an SVG component for an edit icon with green color
export const editSVG = ({ color = theme.colors.primary }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.02459 1.10404C8.36294 0.76552 8.91162 0.76529 9.25025 1.10352L10.7458 2.5973C11.0845 2.93567 11.0848 3.48461 10.7463 3.82327L4.59556 9.97761C4.47483 10.0984 4.3211 10.1808 4.15368 10.2146L1 10.85L1.63659 7.70014C1.67036 7.53308 1.75263 7.37967 1.87313 7.25911L8.02459 1.10404Z"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

// Defines an SVG component for a grey edit icon
export const greyEditSVG = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25 7.875L4.4375 9.75M1.9375 7.875L8.09967 1.49762C8.76316 0.834128 9.83889 0.834127 10.5024 1.49762C11.1659 2.16111 11.1659 3.23684 10.5024 3.90033L4.125 10.0625L1 11L1.9375 7.875Z"
      stroke="#7D838A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

// Defines an SVG component for a black edit icon
export const blackEditSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0246 4.75404C11.3629 4.41552 11.9116 4.41529 12.2502 4.75352L13.7458 6.2473C14.0845 6.58567 14.0848 7.13462 13.7463 7.47327L7.59556 13.6276C7.47483 13.7484 7.3211 13.8308 7.15368 13.8646L4 14.5L4.63659 11.3501C4.67036 11.1831 4.75263 11.0297 4.87313 10.9091L11.0246 4.75404Z"
      stroke="#242E2C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
