import { Col } from 'antd'

const ContactSwtchForm: React.FC = () => {
  return (
    <Col>
      <img
        style={{ width: '100%', marginBottom: 32 }}
        alt={'Contact SWTCH'}
        src={'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg'}
      />
      {/* Replace later with actual image*/}
      <p className="heading-03-bold">{'Contact SWTCH for Assistance'}</p>
      <p className="heading-06-regular">
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
        }
      </p>
    </Col>
  )
}

export default ContactSwtchForm
