import { ApiError } from 'models/error'
import { useNotifications } from './useNotifications'

export const useApiCall = () => {
  const { openErrorNotification } = useNotifications()

  const handleApiCall = async (
    apiCall: () => Promise<any>,
    setLoading: (val: boolean) => void,
    setError: (err: ApiError) => void,
  ): Promise<any> => {
    try {
      setLoading(true)
      return await apiCall() // Execute the passed API call
    } catch (e) {
      setError(e as ApiError) // Handle error
      openErrorNotification((e as any)?.cause?.description || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return {
    handleApiCall,
  }
}
